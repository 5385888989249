import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Link, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { PhoneVerificationField } from '@front/squirtle'

const Title = props =>
  <Grid container item xs={9} justifyContent='center' rowSpacing={1}>
    <Grid item xs={12}>
      <Typography variant={'h5'} color={'priamry'}> {I18n.t('login.register.steps.third', { count: 1 })} </Typography>
    </Grid>
    <Grid item xs={11}>
      <Typography fontSize={12} color='text.label'> {I18n.t('login.register.confirm')} </Typography>
    </Grid>
  </Grid>

const Code = props =>
  <Grid item xs={12}>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <PhoneVerificationField name='code' />
    </Box>
  </Grid>

const Footer = ({ onRequestSubmit, isSubmitting, onChangeGsm, onResendCode, ...props }) =>
  <Grid item xs={12}>
    <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
      <Link href='#' onClick={onResendCode} >
        <Typography color={'primary'} variant={'subtitle1'} fontWeight={600}> {I18n.t('gsm.code.resend')} </Typography>
      </Link>
      <Link href='#' onClick={onChangeGsm}>
        <Typography color={'primary'} variant={'subtitle1'} fontWeight={600}> {I18n.t('gsm.code.change')} </Typography>
      </Link>
      <LoadingButton onClick={onRequestSubmit} loading={!!isSubmitting} variant='outlined'>
        <Typography color={'primary'} variant={'h6'} sx={{ textTransform: 'none' }}>  {I18n.t('action.verify')} </Typography>
      </LoadingButton>
    </Box>
  </Grid>

const ThirdStep = props => <Box my={3}>
  <Grid container justifyContent='center' rowSpacing={3}>
    <Title />
    <Code />
    <Footer {...props} />
  </Grid>
</Box>

export default React.memo(ThirdStep)

import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const { openNotification } = hooks.useNotification()
  const CLIENT_COMMERCIAL_NAME = hooks.useRelayConstant('CLIENT_COMMERCIAL_NAME')
  const CLIENT_CGU_PAGE = hooks.useRelayConstant('CLIENT_CGU_PAGE')
  const [, signin] = hooks.useAuthFunction('signin')
  const [, signup] = hooks.useAuthFunction('signup')
  const isSubmitting = hooks.useFormStatus('isSubmitting')
  const [, setStep] = hooks.useFormState('step')
  const [email, password, first_name, last_name, gsm] = hooks.useFieldValues(['email', 'password', 'first_name', 'last_name', 'gsm'])
  const values = { email, password, first_name, last_name, gsm }
  const onRequestSubmit = useCallback(Callbacks.onRequestSubmitHandler(setStep, signin, signup, values, openNotification), [setStep, signin, signup, values, openNotification])

  const mergedProps = {
    CLIENT_COMMERCIAL_NAME,
    CLIENT_CGU_PAGE,
    onRequestSubmit,
    isSubmitting
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer

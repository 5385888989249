import React from 'react'

import { Box, Slide } from '@mui/material'
import BookingFrame from '@onix/frames/Booking'
import MapBackground from '@onix/elements/map/background'

const BookingForm = ({ isLoading, ...props }) =>
  <Box>
    <Slide direction='right' in timeout={500}>
      <Box sx={{
        position: 'absolute',
        left: '40px',
        top: 90,
        minWidth: '680px',
        width: '40%',
        height: 'auto',
        maxHeight: '82%',
        zIndex: 'modal',
        bgcolor: '#F1F1F1',
        borderRadius: 0,
        overflow: 'auto',
        boxShadow: 6,
        p: 3
      }}>
        <BookingFrame {...props} />
      </Box>
    </Slide>
    <MapBackground />
  </Box>


export default React.memo(BookingForm)

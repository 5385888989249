import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Button, Typography } from '@mui/material'
import { TextField } from '@front/squirtle'

import LoadingButton from '@mui/lab/LoadingButton'

const ForgotPage = ({ onRequestChangePassword }) => 
<Box>
  <Grid container justifyContent='center' rowSpacing={8}>
    <Grid container item xs={12} rowSpacing={2} justifyContent='center'>
      <Grid item xs={11}>
        <Typography sx={{ fontSize: '2vw', color: 'primary.main', fontWeight: 200 }}> {I18n.t('password.forgot.title')} </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography sx={{ fontSize: '1.2vw', color: 'text.label', fontWeight: 200 }}> {I18n.t('password.forgot.description')} </Typography>
      </Grid>
    </Grid>
    <Grid item xs={8}>
      <TextField
        autoFocus
        label={I18n.t('email.label', { count: 1 })}
        name='email'
        type='text'
        variant='outlined'
        size={'medium'}
      />
    </Grid>
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <Button onClick={() => location.reload()} variant='outlined' size={'small'}>
          <Typography sx={{ textTransform: 'none', color: 'primary.main', fontSize: '1.6vw', letterSpacing: 1.5 }}> {I18n.t('action.return')} </Typography>
        </Button>
        <LoadingButton onClick={onRequestChangePassword} variant='outlined' size={'small'}>
          <Typography sx={{ textTransform: 'none', color: 'primary.main', fontSize: '1.6vw', letterSpacing: 1.5 }}> {I18n.t('action.send')} </Typography>
        </LoadingButton>
      </Box>
    </Grid >
  </Grid >
</Box >

export default React.memo(ForgotPage)

import React from 'react'
import { I18n, CDNImage, CDNSVG } from '@front/volcanion'

import { Fade, Box, Grid, Link, Divider, Typography, Icon, IconButton } from '@mui/material'
import LoginForm from './Login'
import RegisterForm from './Register'

import MapBackground from '@onix/elements/map/background/component'

const LinkComponent = ({ onClick, label, typographyProps }) =>
  <Link href={'#'} onClick={onClick}>
    <Typography color='primary' {...typographyProps}>
      {label}
    </Typography>
  </Link>

const Header = props =>
  <Box onClick={() => window.location.reload()}>
    <CDNImage name={'logo'} category={'images'} style={{ maxHeight: window.innerHeight / 5 }} />
  </Box>

const Footer = ({
  isLogin,
  onChangeLogin,
  lang = 'DEFAULT',
  CLIENT_COMMERCIAL_NAME,
  CLIENT_CONTACT_EMAIL,
  CLIENT_CONTACT_URL,
  sendContactMail
}) =>
  <Grid container rowSpacing={4} justifyContent='center'>
    <Grid item xs={12}>
      <Typography sx={{ textAlign: 'center', fontSize: 12, color: 'text.label' }}>
        {I18n.t('term.signup', { name: CLIENT_COMMERCIAL_NAME })}
        <LinkComponent
          onClick={sendContactMail}
          label={CLIENT_CONTACT_EMAIL}
          typographyProps={{ sx: { fontSize: 12, fontWeight: 600 } }}
        />
      </Typography>
    </Grid>
    <Grid item xs={10}><Divider /></Grid>
    <Grid item xs={10}>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <LinkComponent
          onClick={onChangeLogin}
          label={I18n.t(`action.${!!isLogin ? 'signup' : 'login'}`)}
          typographyProps={{ variant: 'h6', sx: { fontWeight: 600 } }}
        />
        <LinkComponent
          onClick={() => window.open(CLIENT_CONTACT_URL[lang])}
          label={I18n.t('action.contact_us')}
          typographyProps={{ variant: 'h6', sx: { fontWeight: 600 } }}
        />
      </Box>
    </Grid>
  </Grid >

const Authentication = ({ onAppClick, isApple, isMobile, ...props }) => (
  <Box>
    <Box sx={{
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      left: 0,
      top: 0,
      width: '45%',
      height: '100%',
      overflow: 'auto',
      zIndex: 'modal',
      bgcolor: 'rgb(241, 241, 241)',
      boxShadow: 23,
      p: 2
    }}>
      <Fade in timeout={2000}>
        <Grid container justifyContent={'center'} direction={'column'} alignItems='center' spacing={!!isMobile ? 0 : 4}>
          <Grid item xs={12}><Header /></Grid>
          <Grid item xs={12} hidden={!props?.isLogin}><LoginForm /></Grid>
          <Grid item xs={12} hidden={!!props?.isLogin}><RegisterForm /></Grid>
          <Grid item xs={12} hidden={!isMobile}>
            <IconButton onClick={onAppClick}>
              <CDNSVG name={!!isApple ? 'download_apple' : 'download_android'} type='icon' />
            </IconButton>
          </Grid>
          <Grid item xs={12}><Footer {...props} /></Grid>
        </Grid>
      </Fade >
    </Box >
    <MapBackground />
  </Box>
)

export default React.memo(Authentication)

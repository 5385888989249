import React from 'react'

import InputsSection from './Section/Inputs'
import ForgotSection from './Section/Forgot'

const AuthenticationPage = ({ isForgot, ...rest }) =>
!isForgot ? <InputsSection {...rest} /> : <ForgotSection {...rest} />


export default React.memo(AuthenticationPage)

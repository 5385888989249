import React, { useState, useCallback } from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

import HookUtils from '@front/volcanion/utils/hooks'

const withContainer = Component => ({ isLoading = false, ...props }) => {
  const [company] = hooks.useGlobalState('selectedCompany')
  const [user, state] = hooks.useActiveUser({ populate: ['info', 'customerinfo', 'auth', 'user_companies'] })
  const [inPanel, setInPanel] = useState(true)
  const initialRoleFilter = hooks.useCompanyFilter(company, { model_name: 'order' })

  const hasCompanies = !_.isEmpty(_.filter(_.get(user, 'user_companies'), elem => !!elem.administration_level))

  const onInPanelChange = useCallback(() => {
    setInPanel(!inPanel)
  }, [setInPanel, inPanel])


  const mergedProps = {
    hasCompanies,
    inPanel,
    onInPanelChange,
    initialRoleFilter,
    company,
    user
  }
  return (
    <Loader isLoading={HookUtils.getLoadingState(state)}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer

import React from 'react'

import { Grid } from '@mui/material'

import RegistrationStepper from './Stepper'
import FirstStep from './Step/First'
import SecondStep from './Step/Second'
import ThirdStep from './Step/Third'

const RegsiterPage = ({ step }) =>
  <Grid container direction='column' spacing={3}>
    <Grid item xs={12}><RegistrationStepper value={step} /></Grid>
    <Grid item xs={12} hidden={step !== 0}><FirstStep /></Grid>
    <Grid item xs={12} hidden={step !== 1}><SecondStep /></Grid>
    <Grid item xs={12} hidden={step !== 2}><ThirdStep /></Grid>
  </Grid>

export default React.memo(RegsiterPage)

import React from 'react'
import { I18n, CDNSVG } from '@front/volcanion'

import { Box, Collapse, Grid, Typography } from '@mui/material'
import { MenuSelectorField, ModelAutocompleteField, DateTimeField } from '@front/squirtle'
import { UserUtils } from '@front/volcanion/utils'

const SearchForm = ({
  initialContractRoleFilter,
  initialServiceRoleFilter,
  initialUserRoleFilter,
  order_status,
}) =>
  <Collapse in timeout={1000}>
    <Box sx={{ bgcolor: 'extraWhite', p: 3, boxShadow: 6 }}>
      <Grid container alignItems='center' rowSpacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CDNSVG name='orders' fill='primary.main' category={'icons'} />
            <Box sx={{ ml: 2 }}>
              <Typography sx={{ fontSize: 21, color: 'primary.main', letterSpacing: 1.5 }}> {I18n.t('order.search')}: </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item container xs={11} spacing={1}>
          <Grid item xs>
            <ModelAutocompleteField
              name='do'
              label={I18n.t('companyuser.label', { count: 1 })}
              model_name={'companyuser'}
              searchMode={'search'}
              searchQuery={'startsWith'}
              autocompleteMutator={q => ({
                or: [
                  { contact: { last_name: { startsWith: q } } },
                  { user: { info: { last_name: { startsWith: q } } } },
                  { user: { info: { first_name: { startsWith: q } } } },
                  { contact: { first_name: { startsWith: q } } }
                ]
              })}
              loadOnMount
              config={{
                forced_filter: initialUserRoleFilter,
                watchers: [initialUserRoleFilter],
                populate: ['contact', 'user.customerinfo'],
                mutator: elem => ({
                  label: UserUtils.getFormatedName({
                    ...elem?.contact,
                    designation: elem?.user?.customerinfo?.name
                  }),
                  user_id: _.get(elem, 'user.user_id'),
                  ...elem
                })
              }}
              labelKeys={['label']}
              selectKeys={['user_id']}
            />
          </Grid>
          <Grid item xs>
            <ModelAutocompleteField
              name='companycontract'
              label={I18n.t('companycontract.label', { count: 1 })}
              model_name={'companycontract'}
              searchMode={'search'}
              searchQuery={'='}
              loadOnMount
              labelKeys={['client_id']}
              config={{
                forced_filter: initialContractRoleFilter,
                watchers: [initialContractRoleFilter]
              }}
            />
          </Grid>
          <Grid item xs>
            <ModelAutocompleteField
              name='companyservice'
              label={I18n.t('companyservice.label', { count: 1 })}
              model_name={'companyservice'}
              searchMode={'search'}
              searchQuery={'='}
              labelKeys={['client_id']}
              loadOnMount
              config={{
                forced_filter: initialServiceRoleFilter,
                watchers: [initialServiceRoleFilter]
              }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={11} spacing={2}>
          <Grid item xs>
            <MenuSelectorField
              name='status'
              label={I18n.t('order.status.label', { count: 1 })}
              options={order_status}
              clearOnEmpty
              selectFirst
              noneLabel={I18n.t('all')}
            />
          </Grid>
          <Grid item xs>
            <DateTimeField
              label={I18n.t('date.search')}
              name={'requestedAt_start'}
              compact
              renderInputProps={{
                InputLabelProps: { shrink: true }
              }}
            />
          </Grid>
          <Grid item xs>
            <DateTimeField
              label={I18n.t('date.at')}
              name={'requestedAt_end'}
              compact
              renderInputProps={{
                InputLabelProps: { shrink: true }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Collapse >



export default React.memo(SearchForm)

import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const { openNotification } = hooks.useNotification()
  const [, signin] = hooks.useAuthFunction('signin')
  const [, setStep] = hooks.useFormState('step')
  const isSubmitting = hooks.useFormStatus('isSubmitting')

  const [email, password, first_name, last_name, gsm, code] = hooks.useFieldValues(['email', 'password', 'first_name', 'last_name', 'gsm', 'code'])
  const values = { email, password, first_name, last_name, gsm, code }

  const onChangeGsm = useCallback(() => setStep(1), [setStep])

  const onResendCode = useCallback(Callbacks.onResendCodeHandler(openNotification, signin, values), [openNotification, signin, values])
  const onRequestSubmit = useCallback(Callbacks.onRequestSubmitHandler(openNotification, signin, values), [openNotification, signin, values])

  const mergedProps = {
    onResendCode,
    onChangeGsm,
    onRequestSubmit,
    isSubmitting
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer

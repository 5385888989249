import React, { } from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, IconButton, Typography } from '@mui/material'
import { TextField } from '@front/squirtle'
import LoadingButton from '@mui/lab/LoadingButton'

import PasswordComplexity from '@onix/elements/complexity/password'

import { VisibilityOff, Visibility } from '@mui/icons-material'

const FirstStep = props => <Box>
  <Grid container justifyContent='center' rowSpacing={4}>
    <Grid item xs={9}>
      <Typography variant='h4' color='primary' sx={{ letterSpacing: 1.5 }}>
        {I18n.t('login.register.steps.first')}
      </Typography>
    </Grid>
    <Grid item xs={11} sm={8}>
      <TextField
        autoFocus
        label={I18n.t('email.label', { count: 1 })}
        name='email'
        type='text'
        variant='outlined'
        size='medium'
      />
    </Grid>
    <Grid item xs={11} sm={8}>
      <TextField
        label={I18n.t('password.label', { count: 1 })}
        name='password'
        type={props?.visible ? 'text' : 'password'}
        InputProps={{
          endAdornment:
            <IconButton onClick={props?.onVisibleChange} sx={{ ml: 1 }} disableRipple>
              {props?.visible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        }}
        variant='outlined'
        size='medium'
      />
    </Grid>
    <Grid item xs={12} hidden={!props?.password}>
      <PasswordComplexity value={props?.password} disableTitle={false} onScoreChange={props?.setScore} />
    </Grid>
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <LoadingButton onClick={props?.onRequestSubmit} loading={!!props?.isSubmitting} variant='outlined'>
          <Typography color='primary' variant='h6' sx={{ textTransform: 'none' }}>  {I18n.t('action.register')} </Typography>
        </LoadingButton>
      </Box>
    </Grid>
  </Grid>
</Box>

export default React.memo(FirstStep)

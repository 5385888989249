import React from 'react'

import moment from 'moment'

import { Box, Collapse, Grid, Slide, IconButton, Icon, Paper } from '@mui/material'
import UserFrame from '@onix/frames/UserPanel'

import OrderTabManager from '@onix/elements/headers/order'
import QuickOrderFrame from '@onix/frames/QuickOrder'
import OrdersFrame from '@onix/frames/Orders'
import MapBackground from '@onix/elements/map/background'

const Profile = ({ user, isLoading, onTabChange, onQuickOrderChange, quickOrder, tab, forcedFormToFilter, onLeftSlideEnd, onRightSlideEnd, onInPanelChange, rightSlideIsLoad, leftSlideIsLoad, inPanel, isB2B, ...props }) => <Box>
  <Box sx={{
    position: 'relative',
    top: 70,
    paddingRight: '3px',
    paddingLeft: '3px',
    height: window.innerHeigh,
    zIndex: 9,
    backgroundColor: 'transparent',
    display: 'flex',
  }}>


    <Collapse orientation='horizontal' collapsedSize={20} in={inPanel} component={Paper} sx={{ backgroundColor: 'extraWhite' }}>
      <Box sx={{ boxShadow: 6, backgroundColor: 'extraWhite', overflow: 'auto' }}>
        <UserFrame user_id={_.get(user, 'user_id')} />
      </Box>
    </Collapse >

    <Box sx={{ ml: '2px' }}>
      <IconButton onClick={onInPanelChange} color='primary' sx={{ boxShadow: 6, ml: -2, mt: 6, position: 'absolute', zIndex: 999, backgroundColor: 'extraWhite' }} disableRipple size='small'>
        <Icon>{!!inPanel ? 'arrow_backward' : 'arrow_forward'} </Icon>
      </IconButton>
      <Slide direction='left' in timeout={1200} addEndListener={onRightSlideEnd}>
        <Box sx={{ boxShadow: 6, bgcolor: 'extraWhite', overflow: 'auto', maxHeight: window.innerHeight }}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <OrderTabManager value={tab} onChange={onTabChange} onQuickOrderChange={onQuickOrderChange} />
            </Grid>
            <Grid item xs={12}>

              {/* <Collapse in={!!quickOrder && tab !== 3 && !!rightSlideIsLoad && !isB2B}> */}
              <Collapse in={false}>
                <QuickOrderFrame onQuickOrderChange={onQuickOrderChange} />
              </Collapse>
              <OrdersFrame
                quickOrder={quickOrder}
                allowCreate={false}
                groupBy={tab === 3 ? item => moment(item.requestedAt).format('MMMM') : null}
                submitWatchers={[tab]}
                forcedFormToFilter={forcedFormToFilter}
              />
            </Grid>
          </Grid>
        </Box>
      </Slide >
    </Box>



  </Box >
  <MapBackground />
</Box>

export default React.memo(Profile)

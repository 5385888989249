import React from 'react'

import { Box, IconButton, Icon } from '@mui/material'

import DashboardFrame from '@onix/frames/Dashboard'
import AdminFrame from '@onix/frames/Admin'
import OrdersFrame from '@onix/frames/Orders'
import ContractsFrame from '@onix/frames/Contracts'
import ContractFrame from '@onix/frames/Contract'
import UsersFrame from '@onix/frames/Users'
import UserFrame from '@onix/frames/User'
import ContactsFrame from '@onix/frames/Contacts'
import ContactFrame from '@onix/frames/Contact'
import ServiceFrame from '@onix/frames/Service'
import NotFound from './NotFound'

import FormComponent from './Orders/Form'
import { Routes, Route } from '@front/squirtle'

const NotFoundComponent = props => <>
  <Box sx={{
    display: 'flex',
    position: 'absolute',
    left: '0px',
    top: '60px',
    width: '100%',
    height: '100%',
    zIndex: 'modal'
  }}>
    <NotFound />
  </Box >
</>

const AdminComponent = ({ user, company, initialRoleFilter, inPanel, onInPanelChange, ...props }) =>
  <Box sx={{
    position: 'relative',
    top: 60,
    height: window.innerHeight,
    zIndex: 9,
    backgroundColor: 'transparent',
    display: 'flex'
  }}>
    <Box sx={{ boxShadow: 6, overflow: 'auto', height: window.innerHeight, backgroundColor: 'extraWhite' }} hidden={!inPanel}>
      <AdminFrame user_id={_.get(user, 'user_id')} />
    </Box>

    <Box sx={{ width: '100%' }}>
      <IconButton onClick={onInPanelChange} color='primary' sx={{ boxShadow: 6, ml: inPanel ? -2 : 0, mt: 6, position: 'absolute', zIndex: 999, backgroundColor: 'extraWhite' }} disableRipple size='small'>
        <Icon>{!!inPanel ? 'arrow_backward' : 'arrow_forward'} </Icon>
      </IconButton>
      <Box sx={{ boxShadow: 6, bgcolor: 'extraWhite', overflow: 'auto', maxHeight: window.innerHeight, padding: 2 }}>
        <Routes>
          <Route index element={<DashboardFrame />} />
          <Route path='/dashboard/*' element={<DashboardFrame />} />
          <Route path='/orders/*' element={<OrdersFrame
            FormComponent={FormComponent}
            disableButton={false}
            forcedFormToFilter={initialRoleFilter}
            isB2B
            submitWatchers={[company]}
          />} />
          <Route path='/contracts/*' element={<ContractsFrame />} />
          <Route path='/contract/:companycontract_id' element={<ContractFrame />} />
          <Route path='/users/*' element={<UsersFrame />} />
          <Route path='/user/create' element={<UserFrame />} />
          <Route path='/user/:companyuser_id' element={<UserFrame />} />
          <Route path='/contacts/*' element={<ContactsFrame />} />
          <Route path='/contact/create' element={<ContactFrame />} />
          <Route path='/contact/:companycontact_id' element={<ContactFrame />} />
          <Route path='/service/:companyservice_id' element={<ServiceFrame />} />
        </Routes>
      </Box>
    </Box>
  </Box >

const AdminPage = props => !!props?.hasCompanies ? <AdminComponent {...props} /> : <NotFoundComponent />


export default React.memo(AdminPage)

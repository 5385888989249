import _ from "lodash"

class Callbacks {
  static sendContactMailHandler(CLIENT_CONTACT_EMAIL) {
    return function sendContactMail() {
      window.location.assign(
        `mailto: ${CLIENT_CONTACT_EMAIL} 
        ?Subject= Unne question ? 
        `
      )
    }
  }
}

export default Callbacks

import React from 'react'

import { Routes, Route } from "@front/squirtle"

import OrderFormComponent from './container'

const BookingPage = props =>
  <Routes>
    <Route index element={<OrderFormComponent />} />
  </Routes>

export default React.memo(BookingPage)

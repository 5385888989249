import React from 'react'

import { Routes, Route } from "@front/squirtle"

import ProfileComponent from './container'

const ProfilePage = props =>
  <Routes>
    <Route index element={<ProfileComponent />} />
  </Routes>

export default React.memo(ProfilePage)

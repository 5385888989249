import { I18n } from '@front/volcanion'

class Callbacks {
  static onSubmitHandler(signin) {
    return async function onSubmit(values, extra, meta, state) {
      const { isForgot } = state
      const { email, password } = values
      try {
        if (!!isForgot) return
        await signin({ email, password })
      } catch (err) {
        throw err
      }
    }
  }
  static getFailedNotificationHandler() {
    return function getFailedNotification(err, final_values, extra, meta, state) {
      if (_.includes(['PASSWORD_INCORRECT'], err.getCode()))
        return [I18n.t('password.incorrect'), { variant: 'error' }]
      else return [I18n.t('notification.order.failed'), { variant: 'error' }]

    }
  }
  static getSuccessNotificationdHandler() {
    return function getSuccessNotificationd(result, final_values, extra, meta, state) {
    }
  }
}

export default Callbacks

import React, { useEffect } from 'react'
import clsx from 'clsx'

import { hooks } from '@front/volcanion'

import TopBar from '@onix/elements/topbar'
import Authentication from './Authentication'
import { Loader, Router, Routes, Route } from '@front/squirtle'

import './styles.scss'

import Booking from './Booking'
import Admin from './Admin'
import Profile from './Profile'

const PrivateRouter = () => {
  return (
    <Routes>
      <Route index element={<Booking />} />
      <Route path='/booking/*' element={<Booking />} />
      <Route path='/profile/*' element={<Profile />} />
      <Route path='/admin/*' element={<Admin />} />
    </Routes>
  )
}
const MemoRouter = React.memo(PrivateRouter)
const withDrawer = (Component) => (props) => {
  const [isDrawerOpen] = hooks.useGlobalState('isDrawerOpen')
  const { CPP_URL } = hooks.useRelayConstants(['CPP_URL'])
  return <Component isDrawerOpen={isDrawerOpen} CppURL={CPP_URL}{...props} />
}


const PrivateRoute = ({ isDrawerOpen, CppURL }) => {
  return (
    <div className={clsx('root', { ['shift']: isDrawerOpen })}>
      <Router>
        <TopBar />
        <MemoRouter />
      </Router>
    </div >
  )
}
const DrawerPrivateRoute = React.memo(withDrawer(PrivateRoute))

const withAuth = (Component) => (props) => {
  const isReady = hooks.useReadyStatus()
  const isAuthenticated = hooks.useAuthenticated()
  return <Component isReady={isReady} isAuthenticated={isAuthenticated} {...props} />
}

const Pages = ({ isReady, isAuthenticated }) => {
  const [, setMode] = hooks.useGlobalState('mode')

  useEffect(() => {
    setMode('default')
  }, [])

  return (
    <Loader isLoading={!isReady}>
      {!!isAuthenticated ? <DrawerPrivateRoute /> : <Authentication />}
    </Loader>
  )
}
const AuthPrivateRoute = React.memo(withAuth(Pages))

export default React.memo(AuthPrivateRoute);

import React, { useCallback } from 'react'
import { hooks, Form } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const [, signin] = hooks.useAuthFunction('signin')

  const formProps = {
    name: 'register_form',
    status: {
      isReady: true,
      isForgot: false
    },
    initialState: {
      isReadOnly: false,
      step: 0
    },
    initialValues: {},
    onSubmit: useCallback(Callbacks.onSubmitHandler(signin), [signin]),
    getSuccessNotification: useCallback(Callbacks.getSuccessNotificationdHandler(), []),
    getFailedNotification: useCallback(Callbacks.getFailedNotificationHandler(), []),
  }

  const mergedProps = {
  }

  return (
    <Form {...formProps}>
      <Component {...mergedProps} />
    </Form>
  )
}

export default withForm

import React, { useCallback, useState } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [isLogin, setLogin] = useState(true)
  const { CLIENT_COMMERCIAL_NAME, CLIENT_CONTACT_EMAIL, CLIENT_MAIN_SITE, SELF_SOURCE_ID
  } = hooks.useRelayConstants(['CLIENT_COMMERCIAL_NAME', 'CLIENT_CONTACT_EMAIL', 'CLIENT_MAIN_SITE', 'SELF_SOURCE_ID'])

  const onChangeLogin = useCallback(() => setLogin(!isLogin), [isLogin, setLogin])
  const sendContactMail = useCallback(Callbacks.sendContactMailHandler(CLIENT_CONTACT_EMAIL, CLIENT_COMMERCIAL_NAME), [CLIENT_CONTACT_EMAIL, CLIENT_COMMERCIAL_NAME])

  const CLIENT_CONTACT_URL = {
    NL: hooks.useRelayConstant('CLIENT_CONTACT_URL_NL'),
    EN: hooks.useRelayConstant('CLIENT_CONTACT_URL_EN'),
    FR: hooks.useRelayConstant('CLIENT_CONTACT_URL_FR'),
    DEFAULT: hooks.useRelayConstant('CLIENT_CONTACT_URL')
  }

  const isApple = navigator.userAgent.match(/(iPhone|iPod|iPad|iphone)/)
  const isMobile = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|android|iphone)/)

  const onAppClick = useCallback(() => {
    if (!!isMobile) {
      window.location.href = `${CLIENT_MAIN_SITE}/${SELF_SOURCE_ID}`
    }
  }, [isMobile, CLIENT_MAIN_SITE, SELF_SOURCE_ID])

  const mergedProps = {
    isApple,
    isMobile,
    isLogin,
    CLIENT_COMMERCIAL_NAME,
    CLIENT_CONTACT_EMAIL,
    CLIENT_CONTACT_URL,
    sendContactMail,
    onChangeLogin,
    onAppClick
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer

import React, { useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const { openNotification } = hooks.useNotification()
  const [, requestPasswordChange] = hooks.useAuthFunction('resetPassword')
  const [email] = hooks.useFieldValues(['email'])

  const onRequestChangePassword = useCallback(() => {
    !!email && requestPasswordChange({ email })
    openNotification(I18n.t('password.forgot.success'), { variant: 'success' })
  }, [requestPasswordChange, email])

  const mergedProps = {
    onRequestChangePassword
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer

import React from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

import Component from './component'

const withContainer = props => {
  const [user] = hooks.useActiveUser({ populate: ['info', 'customerinfo', 'auth'] })
  const mergedProps = {
    user
  }

  return (<Loader isLoading={!user}>
    <Component {...mergedProps} />
  </Loader>
  )
}

export default withContainer

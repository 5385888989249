import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [isForgot] = hooks.useFormState('isForgot')

  const mergedProps = {
    isForgot
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer

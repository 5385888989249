

import { useTheme } from '@mui/styles'
class StatusUtils {
  static getColor = status => {
    const theme = useTheme()
    if (!!_.includes(['inactive', 'archived', 'suspended'], status)) return theme.palette['orange']
    else if (status === 'active') return theme.palette['green']
    else return theme.palette['red']
  }

}

export default StatusUtils

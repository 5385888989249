import React, { useState, useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [isForgot, setForgot] = hooks.useFormState('isForgot')
  const [visible, setVisible] = useState(false)

  const isSubmitting = hooks.useFormStatus('isSubmitting')
  const requestSubmit = hooks.useFormFunction('requestSubmit')

  const onForgotChange = useCallback(() => setForgot(!isForgot), [isForgot, setForgot])
  const onVisibleChange = useCallback(() => setVisible(!visible), [visible, setVisible])

  const mergedProps = {
    visible,
    requestSubmit,
    isSubmitting,
    onForgotChange,
    onVisibleChange,
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer

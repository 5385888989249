import React from 'react'
import { I18n, hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  const [selectedCompany] = hooks.useGlobalState('selectedCompany')

  const order_status = _.compact([{
    label: I18n.t('order.status.in_progress'),
    value: 'in_progress'
  }, {
    label: I18n.t('order.status.planned'),
    value: 'planned'
  }, {
    label: I18n.t('order.status.finished'),
    value: 'complete'
  }, {
    label: I18n.t('order.status.cancel', { count: -1 }),
    value: 'canceled'
  }])

  const initialContractRoleFilter = hooks.useCompanyFilter(selectedCompany, { model_name: 'companycontract' })
  const initialServiceRoleFilter = hooks.useCompanyFilter(selectedCompany, { model_name: 'companyservice' })
  const initialUserRoleFilter = hooks.useCompanyFilter(selectedCompany, { model_name: 'companyuser' })

  const mergedProps = {
    initialContractRoleFilter,
    initialServiceRoleFilter,
    initialUserRoleFilter,
    requestSubmit,
    order_status
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer

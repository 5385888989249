import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import Component from './component'

const withContainer = props => {
  const [tab, setTab] = hooks.useGlobalState('tab')
  const [quickOrder, setQuickOrder] = useState(true)
  const [leftSlideIsLoad, setLeftSlideIsLoad] = useState(false)
  const [rightSlideIsLoad, setRightSlideIsLoad] = useState(false)
  const [inPanel, setInPanel] = hooks.useGlobalState('inPanel')

  const [user] = hooks.useActiveUser({ populate: ['info', 'customerinfo', 'auth', 'user_companies'] })

  useEffect(() => {
    setInPanel(true)
  }, [])

  const onTabChange = useCallback(value => {
    setTab(value)
  }, [setTab])

  const onInPanelChange = useCallback(() => {
    setInPanel(!inPanel)
  }, [setInPanel, inPanel])

  const onQuickOrderChange = useCallback(() => {
    setQuickOrder(!quickOrder)
  }, [setQuickOrder, quickOrder])

  const onLeftSlideEnd = useCallback((q) => {
    if (!leftSlideIsLoad) setLeftSlideIsLoad(!leftSlideIsLoad)
    else setTimeout(() => setLeftSlideIsLoad(!leftSlideIsLoad), 1500)

  }, [leftSlideIsLoad, setLeftSlideIsLoad])

  const onRightSlideEnd = useCallback((q) => {
    setTimeout(() => setRightSlideIsLoad(!rightSlideIsLoad), 500)
  }, [rightSlideIsLoad, setRightSlideIsLoad])

  useEffect(() => {
    if (!tab)
      setTab(1)
  }, [])

  const forcedFormToFilter = useMemo(() => {
    switch (tab) {
      case 3:
        return {
          confirmed: true,
          status: ['canceled', 'complete'],
        }
      case 2:
        return {
          confirmed: true,
          schedule_type: ['planned'],
          status: ['created']
        }
      case 1:
        return {
          confirmed: true,
          or: [
            {
              schedule_type: ['immediate'],
              status: ['created', 'standby', 'attributed']
            },
            {
              schedule_type: ['planned'],
              status: ['attributed', 'standby']
            }
          ]
        }
    }
  }, [tab])

  const mergedProps = {
    forcedFormToFilter,
    isB2B: _.isEmpty(_.get(user, 'user_companies')),
    onRightSlideEnd,
    rightSlideIsLoad,
    onLeftSlideEnd,
    leftSlideIsLoad,
    inPanel,
    quickOrder,
    onQuickOrderChange,
    onTabChange,
    onInPanelChange,
    tab,
    user
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer

import { I18n } from '@front/volcanion'

class Callbacks {
  static onSubmitHandler(signin, openNotification) {
    return async function onSubmit(values, extra, meta, state) {
      const { isForgot } = state
      const { email, password } = values
      if (!email.match(/[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i)) {
        openNotification(I18n.t('email.incorrect'), { variant: 'error' })
        throw { code: 'form' }
      }
      try {
        if (!!isForgot) return
        await signin({ email, password })
      } catch (err) {
        throw err
      }
    }
  }
  static getFailedNotificationHandler() {
    return function getFailedNotification(err, final_values, extra, meta, state) {
      const { isForgot } = state
      if (!!isForgot) return [I18n.t('password.forgot.success'), { variant: 'success' }]
      else if (_.includes(['PASSWORD_INCORRECT', 'RECORD_NOTFOUND'], err.getCode()))
        return [I18n.t('password.incorrect'), { variant: 'error' }]
      else if (err.getCode() === 'form') return
      else return [I18n.t('notification.order.failed'), { variant: 'error' }]

    }
  }
  static getSuccessNotificationdHandler() {
    return function getSuccessNotificationd(result, final_values, extra, meta, state) {
      const { isForgot } = state
      if (!!isForgot) return [I18n.t('password.forgot.success'), { variant: 'success' }]
    }
  }
}

export default Callbacks

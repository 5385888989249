import React, { useCallback } from 'react'
import { hooks, Form } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const [, signin] = hooks.useAuthFunction('signin')
  const { openNotification } = hooks.useNotification()
  const formProps = {
    name: 'login_form',
    status: {
      isReady: true,
      isForgot: false
    },
    initialState: {
      isReadOnly: false
    },
    initialValues: {},
    closeOnSuccess: false,
    submitOnEnter: true,
    onSubmit: useCallback(Callbacks.onSubmitHandler(signin, openNotification), [signin, openNotification]),
    getSuccessNotification: useCallback(Callbacks.getSuccessNotificationdHandler(), []),
    getFailedNotification: useCallback(Callbacks.getFailedNotificationHandler(), []),
  }

  const mergedProps = {
  }

  return (
    <Form {...formProps}>
      <Component {...mergedProps} />
    </Form>
  )
}

export default withForm

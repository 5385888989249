import React from 'react'
import { hooks, I18n, CDNImage } from '@front/volcanion'
import { Box, Typography, Button, Grid } from '@mui/material'

const NotFound = props => {
  const navigate = hooks.useNavigate()
  const CDN_HOST = hooks.useRelayConstant('CDN_HOST')
  return <>
    <Box sx={{ bgcolor: '#dadae8', height: '100%', display: 'flex', alignItems: 'center' }}>
      <Grid container rowSpacing={4} justifyContent='center' alignItems='center'>
        <Grid container item xs={12} justifyContent='center' alignItems='center'>
          <Grid item xs={4}>
            <CDNImage category='images' name={'not_found'}/>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button onClick={() => navigate('/booking')} variant={'outlined'} size={'large'}> {I18n.t('action.back_home')} </Button>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 15, fontWeight: 600, color: 'primary.main', textAlign: 'center' }}>
            {I18n.t('oops')}
          </Typography>

        </Grid>
      </Grid>
    </Box >
  </>
}

export default React.memo(NotFound)

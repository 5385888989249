import { I18n } from '@front/volcanion'

class Callbacks {
  static onRequestSubmitHandler(setStep, signin, signup, values, openNotification) {
    return async function onRequestSubmit() {
      const { first_name, last_name, gsm } = values
      if (!first_name) {
        openNotification(I18n.t('user.first_name_mandatory'), { variant: 'error' })
        return
      }
      else if (!last_name) {
        openNotification(I18n.t('user.last_name_mandatory'), { variant: 'error' })
        return
      }
      else if (!gsm) {
        openNotification(I18n.t('gsm.mandatory'), { variant: 'error' })
        return
      }
      try {
        await signup(values)
        setStep(2)
        await signin(values)
      } catch (err) {
        throw err
      }
    }
  }
}

export default Callbacks

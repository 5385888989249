import { I18n } from '@front/volcanion'
class Callbacks {
  static onRequestSubmitHandler(openNotification, signin, values) {
    return async function onRequestSubmit() {
      const { code } = values
      try {
        if (!code)
          openNotification(I18n.t('gsm.code.empty'), { variant: 'success' })
        await signin(values)
      } catch (err) {
        openNotification(I18n.t('gsm.code.incorrect'), { variant: 'error' })
        throw err
      }
    }
  }
  static onResendCodeHandler(openNotification, signin, values) {
    return async function onRequestSubmit() {
      try {
        await signin(values)
      } catch (err) {
        openNotification(I18n.t('gsm.code.resend_success'), { variant: 'success' })
      }
    }
  }
}

export default Callbacks
